import React, { useState } from "react"
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import NutriFacts from './form/nutrifacts'
import XSNutriFacts from './form/xsnutrifacts'
import NutriForm from './form/nutriform'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SearchAll from '../components/search_all'
import Typography from '@material-ui/core/Typography'
import SearchColumns from './search_columns'
import rowsData from '../../data/all_data_obj_min'
 
const Body = (props) => {

    const theme = useTheme()
    //console.log(props)
    
    return (
        <>
        <Grid className={"pt-12 bg-gradient-to-tr from-green-900 via-green-700 to-green-500 overflow-hidden"}>
            <Grid container direction="row" justify="center" spacing={2}>
                
                <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                    <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-300"}>
                        <Paper elevation={0} className={'p-3 m-2'}>  
                    
                            <h1 className={"p-3 mx-auto text-3xl tracking-widest font-extrabold text-center text-green-700 "}>
                                Pesquise a <span class="font-black text-red-700 uppercase">composição nutricional</span> de alimentos.
                                <br />
                                Atualmente temos {rowsData.alimentos.length} alimentos cadastrados.
                            </h1>

                            <SearchColumns rows={rowsData.alimentos}/>

                        </Paper>
                    </Paper>
                </Grid>

            </Grid>
        </Grid>

        <Grid>

            <div
                className={"h-60 bg-gradient-to-br from-green-900 via-green-700 to-green-500 overflow-hidden"}
                style={{clipPath: "polygon(100% 0, 0 0, 0 77.5%,1% 77.4%,2% 77.1%,3% 76.6%,4% 75.9%,5% 75.05%,6% 74.05%,7% 72.95%,8% 71.75%,9% 70.55%,10% 69.3%,11% 68.05%,12% 66.9%,13% 65.8%,14% 64.8%,15% 64%,16% 63.35%,17% 62.85%,18% 62.6%,19% 62.5%,20% 62.65%,21% 63%,22% 63.5%,23% 64.2%,24% 65.1%,25% 66.1%,26% 67.2%,27% 68.4%,28% 69.65%,29% 70.9%,30% 72.15%,31% 73.3%,32% 74.35%,33% 75.3%,34% 76.1%,35% 76.75%,36% 77.2%,37% 77.45%,38% 77.5%,39% 77.3%,40% 76.95%,41% 76.4%,42% 75.65%,43% 74.75%,44% 73.75%,45% 72.6%,46% 71.4%,47% 70.15%,48% 68.9%,49% 67.7%,50% 66.55%,51% 65.5%,52% 64.55%,53% 63.75%,54% 63.15%,55% 62.75%,56% 62.55%,57% 62.5%,58% 62.7%,59% 63.1%,60% 63.7%,61% 64.45%,62% 65.4%,63% 66.45%,64% 67.6%,65% 68.8%,66% 70.05%,67% 71.3%,68% 72.5%,69% 73.6%,70% 74.65%,71% 75.55%,72% 76.35%,73% 76.9%,74% 77.3%,75% 77.5%,76% 77.45%,77% 77.25%,78% 76.8%,79% 76.2%,80% 75.4%,81% 74.45%,82% 73.4%,83% 72.25%,84% 71.05%,85% 69.8%,86% 68.55%,87% 67.35%,88% 66.2%,89% 65.2%,90% 64.3%,91% 63.55%,92% 63%,93% 62.65%,94% 62.5%,95% 62.55%,96% 62.8%,97% 63.3%,98% 63.9%,99% 64.75%,100% 65.7%)"}}
            >
            </div>

            <Grid container direction="col" justify="center" alignContent="start" spacing={2}>

                <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                    <div class="w-full bg-transparent p-3 mb-6 mx-auto">
                        <Typography variant="h3" component="h1" className={"text-gray-900 text-center"}>
                                CALCULADORA NUTRICIONAL
                        </Typography>
                        <Typography variant="subtitle1" component="p" className={"text-gray-900 text-center"}>
                                Monte a tabela nutricional da sua refeição. Selecione os alimentos da refeição e veja os valores da composição.
                        </Typography>
                    </div>
                </Grid>

            </Grid>

        </Grid>

        
        <Grid>
            <div
                className={"h-60 -mt-40 bg-gradient-to-tr from-green-900 via-green-700 to-green-500 overflow-hidden"}
                style={{clipPath: "polygon(100% 100%,0 100%,0 77.5%,1% 77.4%,2% 77.1%,3% 76.6%,4% 75.9%,5% 75.05%,6% 74.05%,7% 72.95%,8% 71.75%,9% 70.55%,10% 69.3%,11% 68.05%,12% 66.9%,13% 65.8%,14% 64.8%,15% 64%,16% 63.35%,17% 62.85%,18% 62.6%,19% 62.5%,20% 62.65%,21% 63%,22% 63.5%,23% 64.2%,24% 65.1%,25% 66.1%,26% 67.2%,27% 68.4%,28% 69.65%,29% 70.9%,30% 72.15%,31% 73.3%,32% 74.35%,33% 75.3%,34% 76.1%,35% 76.75%,36% 77.2%,37% 77.45%,38% 77.5%,39% 77.3%,40% 76.95%,41% 76.4%,42% 75.65%,43% 74.75%,44% 73.75%,45% 72.6%,46% 71.4%,47% 70.15%,48% 68.9%,49% 67.7%,50% 66.55%,51% 65.5%,52% 64.55%,53% 63.75%,54% 63.15%,55% 62.75%,56% 62.55%,57% 62.5%,58% 62.7%,59% 63.1%,60% 63.7%,61% 64.45%,62% 65.4%,63% 66.45%,64% 67.6%,65% 68.8%,66% 70.05%,67% 71.3%,68% 72.5%,69% 73.6%,70% 74.65%,71% 75.55%,72% 76.35%,73% 76.9%,74% 77.3%,75% 77.5%,76% 77.45%,77% 77.25%,78% 76.8%,79% 76.2%,80% 75.4%,81% 74.45%,82% 73.4%,83% 72.25%,84% 71.05%,85% 69.8%,86% 68.55%,87% 67.35%,88% 66.2%,89% 65.2%,90% 64.3%,91% 63.55%,92% 63%,93% 62.65%,94% 62.5%,95% 62.55%,96% 62.8%,97% 63.3%,98% 63.9%,99% 64.75%,100% 65.7%)"}}
            >
            </div>

            <Grid className={"bg-gradient-to-br from-green-900 via-green-700 to-green-500 overflow-hidden"}>

                <Grid container direction="row" justify="center" alignContent="start" spacing={2}>

                    <Grid item xs={12} sm={7} md={12} lg={5} xl={4}>
                        <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-300"}>
                            <Paper elevation={0} className={'p-3 m-2'}>                       
                                <NutriForm
                                    //ad1={useMediaQuery(theme.breakpoints.down('sm')) ? 'https://via.placeholder.com/320x50?text=320x50' : 'https://via.placeholder.com/468x60?text=468x60'}
                                    
                                    //ad2={useMediaQuery(theme.breakpoints.up('md')) ? 'https://via.placeholder.com/468x60?text=468x60' : 'https://via.placeholder.com/320x50?text=320x50'}
                                />
                            </Paper>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={5} md={12} lg={6} xl={6}>
                        <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-300"}>
                            <Paper elevation={0} className={'p-3 m-2'}>
                                <Hidden only={['xs', 'sm']}>
                                    <NutriFacts
                                        //ad="https://via.placeholder.com/468x60?text=468x60"
                                    />
                                </Hidden>
                                <Hidden only={['md', 'lg', 'xl']}>
                                    <XSNutriFacts
                                        //ad="https://via.placeholder.com/200x200?text=468x60"
                                    />
                                </Hidden>
                            </Paper>
                        </Paper>
                    </Grid>

                </Grid>

                <div 
                    className={"bg-gray-800 h-80 -mb-60 overflow-hidden"}
                    style={{clipPath:"polygon(100% 25%, 0 25%, 100% 0)"}}
                >
                </div>

            </Grid>

        </Grid>
        
        </>
    )
}

export default Body;