import React from "react"
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../components/theme'
import '../css/style.css'
import Nav from '../components/_nav'
import Body from '../components/body_index'
import Footer from '../components/footer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

const IndexPage = (props) => {

	const imgSrc = props.data.allImageSharp.edges[0].node.gatsbyImageData.images.fallback.src
	props.location.host = "infonutrientes.com.br"
	
	return (

		<ThemeProvider theme={theme}>
			<>
				<SEO
					title={"INFO Nutrientes - tudo sobre a composição dos alimentos"}
					description={"Tudo sobre a composição nutricional dos alimentos, calorias, carboidratos, proteínas, gorduras, vitaminas e sais minerais"}
					keywords={"composição dos alimentos, informações nutricionais, macronutrientes, vitaminas, sais minerais"}
					canonical={`https://${props.location.host}/`}
					base_url={`https://${props.location.host}`}
					post_date={"2021-03-15"}
					post_cat={"/"}
					post_cat_name={"Home"}
					post_slug={""}
					post_slug_name={""}
					post_descp_kps={"composição dos alimentos, informações nutricionais, macronutrientes, vitaminas, sais minerais"}
					nutrinfo={false}
					location={props.location}
					image={imgSrc}
				/>
			</>

			<>
				<Nav />
			</>

			<main>
				<Body {...props} />
			</main>

			<>
				<Footer />
			</>

		</ThemeProvider>
	)
}

export default IndexPage

export const pageQuery = graphql`
    query {
        allImageSharp(filter: {original: {src: {regex: "/infonutrientes/"}}}) {
            edges {
                node {
                    gatsbyImageData
                }
            }
        }
    }
`;